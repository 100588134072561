.tooltip {
    &.tooltip-bottom {
        bottom: -55px;
        right: 0;
    }
    .arrow-up {
        position: absolute;
        right: 0;
        top: -14px;
    }
    button {
        min-width: 200px;
        font-size: 16px;
        color: #fff;
        background: #000;
        padding: 10px 26px;
        border-radius: 6px;
        &:hover {
            color: #fff;
        }
    }
}