@use "./../../../assets/scss/abstracts/mixins/mixins.scss" as *;
.banner {
    .main-sec {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.03) 0%, rgba(0, 0, 0, 0.1) 100%), #FFFFFF url("./../../../assets/images/bg-light.png");
        padding: 355px 0 0;
        @media only screen and (max-width: 767px) {
            padding: 200px 0 0;
        }
    }
    .main-sec-hold {
        @extend .grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        // @media only screen and (max-width: 991px) {
        //     align-items: flex-end;
        // }
        @media only screen and (max-width: 768px) {
            align-items: center;
        }
        @media only screen and (max-width: 768px) {
            grid-template-columns: 1fr;
            gap: 35px 0;
        }
        .main-sec-img {
            margin: auto 0 0;
            @media only screen and (max-width: 768px) {
                order: 2;
                margin-inline: auto;
            }
        }
        .main-sec-text {
            font-size: 22px;
            font-weight: 500;
            color: #353535;
            line-height: 2;
            @media only screen and (max-width: 1024) {
                font-size: 20px;
            }
            @media only screen and (max-width: 991) {
                font-size: 20px;
            }
        }
    }
    .banner-hold {
        // padding: 30px 0;
        padding-top: 126px;
        .slick-track {
            display: flex !important;
            align-items: self-end;
            gap: 0 15px;
            .slick-slide { 
                &.slick-current {
                    .slider-nav-hold {
                        background: #F3F3F3;
                        border-radius: 0 0 6px 6px;
                        .image-hold {
                            background: #fff;
                        }
                    }
                }
            }
        }
        .slider-content {
            display: grid !important;
            grid-template-columns: 1fr 570px;
            align-items: center;
            @media only screen and (max-width: 1199px) {
                grid-template-columns: 1fr 500px;
            }
            @media only screen and (max-width: 991px) {
                grid-template-columns: 1fr 350px;
            }
            @media only screen and (max-width: 767px) {
                grid-template-columns: 1fr;
                text-align: center;
            }
            .banner-text {
                h1 {
                    span {
                        @media only screen and (max-width: 374px) {
                            font-size: 52px;
                        }
                    }
                }
                p {
                    font-size: 21px;
                    line-height: 36px;
                    padding: 0 15px 0 0;
                    margin: 0 0 40px;
                    @media only screen and (max-width: 767px) {
                        font-size: 18px;
                        line-height: 28px;
                        padding: 0 15px;
                        margin: 0 0 25px;
                    }
                    br {
                        @media only screen and (max-width: 767px) {
                            display: none;
                        }
                    }
                }
                .btn-icon-hold {
                    align-items: center;
                    margin: 0 0 55px;
                    gap: 0 30px;
                    @media only screen and (max-width: 991px) {
                        gap: 0 18px;
                    }
                    @media only screen and (max-width: 767px) {
                        flex-direction: column;
                        gap: 22px 0;
                        margin: 0 0 20px;
                    }
                    .btn {
                        padding: 20px 46px;
                        @media only screen and (max-width: 1199px) {
                            padding: 20px 30px;
                        }
                        @media only screen and (max-width: 991px) {
                            padding: 20px;
                        }
                    }
                    .banner-icon {
                        align-items: center;
                        gap: 0 32px;
                        @media only screen and (max-width: 991px) {
                            gap: 0 20px;
                        }
                    }
                }
            }
            .image-hold {
                margin-block-start: auto;
            }
        }
        .slider-nav {
            --circle_same_size: 70px;
            padding: 0 22px 15px;
            box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.06);
            border-radius: 6px;
            .slider-nav-hold {
                display: grid !important;
                grid-template-columns: var(--circle_same_size) auto;
                align-items: center;
                gap: 0 15px;
                cursor: pointer;
                padding: 10px 20px;
                .image-hold {
                    width: var(--circle_same_size);
                    height: var(--circle_same_size);
                    @extend .flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 100%;
                    overflow: hidden;
                    background: #F3F3F3;
                    .image-wrap {
                        max-width: 55px;
                    }
                }
                .text-hold {
                    font-weight: 500;
                    padding-inline-end: 5px;
                    span {
                        font-size: 20px;
                        color: #353535;
                    }
                    small {
                        font-size: 16px;
                        color: #939393;
                    }
                }
            }
        }
        .slick-slider {
            --arrow_smae_width: 52px;
            .slick-prev {
                left: -6%;
                @media only screen and (max-width: 1350px) {
                    left: -4%;
                }
                @media only screen and (max-width: 1269px) {
                    left: 0;
                }
            }
            .slick-next {
                right: -6%;
                @media only screen and (max-width: 1350px) {
                    right: -4%;
                }
                @media only screen and (max-width: 1269px) {
                    right: 0;
                }
            }
            .slick-prev, .slick-next {
                width: var(--arrow_smae_width);
                height: var(--arrow_smae_width);
                background: rgba(0, 0, 0, 0.1);
                border-radius: 50%;
                transform: unset;
                z-index: 1;
                @media only screen and (max-width: 1023px) {
                    display: none !important;
                }
                &:before {
                    font-family: unset;
                    font-size: 28px;
                    opacity: 1;
                    color: #000;
                }
            }
        }
    }
}