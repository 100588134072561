@use "./abstracts/mixins/mixins.scss" as *;
@font-face {
    font-family: 'Futura XBlkCnIt BT';
    @include font_style_display(900, italic, swap);
    src: url('./../fonts/FuturaBT-ExtraBlackCondItalic.eot');
    src: url('./../fonts/FuturaBT-ExtraBlackCondItalic.eot?#iefix') format('embedded-opentype'),
        url('./../fonts/FuturaBT-ExtraBlackCondItalic.woff2') format('woff2'),
        url('./../fonts/FuturaBT-ExtraBlackCondItalic.woff') format('woff'),
        url('./../fonts/Futura-BoldItalic.ttf') format('truetype'),
        url('./../fonts/FuturaBT-ExtraBlackCondItalic.svg#FuturaBT-ExtraBlackCondItalic') format('svg');
}