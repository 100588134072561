#footer {
    background: #EBEBEB url(./../../assets/images/bg-light.png);
    background-attachment: fixed;
    text-align: center;
    padding: 15px 0;
    .top-footer {
        .logo {
            padding: 35px 0;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                max-width: 106px;
            }
        }
        .footer-nav {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            border-bottom: 1px solid #C8C8C8;
        }
        .nav-hold, .social-icon {
            display: flex;
            align-items: center;
            gap: 0 40px;
            @media only screen and (max-width: 767px) {
                gap: 25px 20px;
                flex-wrap: wrap;
                justify-content: center;
                text-align: center;
            }
        }
        .nav-hold {
            margin-block-end: 30px;
            li {
                a {
                    font-size: 18px;
                    &.active {
                        color: var(--anchor_hover_color);
                    }
                }
            }
        }
        .social-icon {
            margin-block-end: 40px;
        }
    }
    .copy-wright {
        font-size: 16px;
        color: #6A6A6A;
        padding-block-start: 15px;
        @media only screen and (max-width: 767px) {
            font-size: 14px;
        }
    }
}