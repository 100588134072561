@use "./abstracts/extend.scss" as *;
@use "./abstracts/mixins/mixins.scss" as *;
@use "./abstracts/mixins/root.scss" as *;
@use "./typography.scss" as *;
* {
    @extend %border_box;
    &:before, 
    &:after {
        @extend %border_box;
    }
}
html {
    scroll-behavior: smooth;
}
body {
    color: var(--body_text_color);
    font: 400 var(--body_font_size)/1.2 var(--body_font_family);
    min-width: 320px;
    background: var(--body_bg_color) url(./../images/bg-light.png);
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: none;
    @extend %margin_0;
    background-attachment: fixed;
    scroll-snap-type: y proximity;
}
img {
    max-width: 100%;
    object-fit: cover;
    aspect-ratio: 0/0;
}
a, button {
    font: 400 var(--anchor_button_font_size)/1.2 var(--anchor_button_font_family);
    text-decoration: none;
    color: var(--anchor_color);
    outline: none;
    @extend .inline-block;
    @include transition(opacity, 0.4s, ease, 0s);
    &:hover {
        color: var(--anchor_hover_color);
    }
}
button {
    border: 0;
    background: transparent;
    cursor: pointer;
    @extend %padding_0;
}
p, figure {
    @extend %margin_0;
}
:where(picture, img) {
    @extend .block;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4 {
    font-family: var(--heading_font_family);
    font-weight: 400;
    line-height: 1;
    color: var(--heading_color);
    text-transform: uppercase;
}
h1 {
    font-family: var(--h1_font_family);
    font-size: 80px;
    margin: 0;
    @media only screen and (max-width: 1199px) {
        font-size: 70px;
    }
    @media only screen and (max-width: 991px) {
        font-size: 60px;
    }
    @media only screen and (max-width: 768px) {
        font-size: 44px;
    }
    span {
        font-size: 112px;
        background: linear-gradient(to right, #CE1126 38%, #006341 55%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        padding: 0 10px;
        @media only screen and (max-width: 1199px) {
            font-size: 70px;
            padding: 0 4px;
        }
        @media only screen and (max-width: 991px) {
            padding: 0 2px;
        }
        @media only screen and (max-width: 767px) {
            font-size: 62px;
        }
        @media only screen and (max-width: 479.98px) {
            font-size: 58px;
        }
    }
}
h2 {
    font-size: 52px;
    margin: 0 0 10px;
    @extend .flex;
    align-items: center;
    gap: 0 15px;
    @media only screen and (max-width: 991px) {
        font-size: 35px;
    }
    @media only screen and (max-width: 767px) {
        font-size: 36px;
        justify-content: center;
    }
    @media only screen and (max-width: 639px) {
        font-size: 30px;
    }
    @media only screen and (max-width: 479px) {
        font-size: 25px;
    }
    .image-hold {
        @media only screen and (max-width: 991px) {
            width: 30px;
        }
        @media only screen and (max-width: 767px) {
            width: 30px;
        }
        @media only screen and (max-width: 639px) {
            width: 23px;
        }
    }
}
h3 {
    font-size: 40px;
    @media only screen and (max-width: 767px) {
        font-size: 30px;
    }
}
h4 {
    font-size: 19px;
}
h5 {
    font-family: var(--body_font_family);
    font-size: 24px;
    font-weight: 700;
    margin: 0 0 8px;
}
ul, 
ol {
    @extend %margin_0;
    @extend %padding_0;
    list-style: none;
}
label {
    color: var(--label_color);
    font: 500 var(--body_font_size)/1.2 var(--body_font_family);
    margin-block-end: 7px;
}
input, textarea {
    vertical-align: middle;
    color: var(--input_color);
    font: 500 var(--input_font_size)/1.2 var(--body_font_family);
    background: var(--input_bg_color);
    border: 1px solid var(--input_border_color);
    border-radius: 8px;
    @extend %margin_0;
    min-height: 56px;
    padding: 20px;
    outline: none;
    width: 100%;
    &[type=text], &[type=email], textarea {
        -webkit-appearance: none;
    }
    &::placeholder {
        color: #bbb;
    }
}
textarea {
    resize: none;
    min-height: 120px;
}
#main {
    scroll-snap-type: y proximity;
    section {
        scroll-snap-align: start;
        // min-height: calc(100vh - 126px);
    }
}
.container {
    max-width: 1200px;
    padding: 0 15px;
    margin: 0 auto;
    &:after {
        content: '';
        @extend .block;
        clear: both;
    }
}
#wrapper {
    overflow: hidden;
    width: 100%;
    @extend .relative;
}
.btn {
    min-width: 230px;
    font: 400 var(--btn_font_size)/1.2 var(--btn_font_family);
    padding: 16px 20px;
    border-radius: var(--btn_radius);
    @include transition(all, 0.2s, ease-out, 0s);
    @extend .relative;
    @media only screen and (max-width: 767px) {
        min-width: 120px;
    }
    &.btn-success {
        background-color: var(--btn_success_bg);
        color: var(--btn_success_color);
        @extend .relative;
        &:hover {
            --btn_success_bg: transparent;
            --btn_success_color: #006341;
            background-color: var(--btn_success_bg);
            color: var(--btn_success_color);
            &:before {
                content: "";
                @extend .absolute;
                inset: 0;
                border-radius: var(--btn_radius);
                padding: 2px;
                background: linear-gradient(to right, #006341, #CE1126);
                -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
                -webkit-mask-composite: xor;
            }
        }
    }
    &.btn-border-success {
        background-color: var(--btn_border_success_bg);
        color: var(--btn_border_success_color);
        &:before {
            content: "";
            @extend .absolute;
            inset: 0;
            border-radius: var(--btn_radius);
            padding: 2px;
            background: linear-gradient(to right, #006341, #CE1126);
            -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
            -webkit-mask-composite: xor;
        }
        &:hover {
            --btn_border_success_color: #fff;
            --btn_border_success_bg: #006341;
            background-color: var(--btn_border_success_bg);
            color: var(--btn_border_success_color);
            &:before {
                @extend .static;
                background: transparent;
                padding: 0;
            }
        }
    }
    &.btn-dark {
        background: var(--btn_dark_bg_color);
        color: var(--btn_dark_color);
        border: 1px solid var(--btn_dar_border);
        &:hover {
            --btn_dark_bg_color: transparent;
            --btn_dark_color: #000;
            --btn_dar_border: #000;
            background: var(--btn_dark_bg_color);
            color: var(--btn_dark_color);
            border: 1px solid var(--btn_dar_border);
        }
    }
    &.btn-white {
        background-color: #fff;
        color: #000;
        border: 1px solid transparent;
        &:hover {
            background-color: transparent;
            color: #fff;
            border: 1px solid #fff;
        }
    }
    &.btn-light {
        background: rgba(0, 0, 0, 0.06);
        color: #000;
        &:hover {
            background: #000;
            color: #fff;
        }
    }
    &:disabled {
        background: var(--btn_disabled_bg_color);
        color: var(--btn_disabled_color);
        pointer-events: none;
    }
}
.modal {
    &.modal-overlay {
        position: fixed;
        inset: 0;
        overflow-x: hidden;
        overflow-y: auto;
        z-index: 3;
        background: rgba(0, 0, 0, 0.6);
    }
    .modal-lg {
        min-height: calc(100% - 3.5rem);
        max-width: 820px;
        width: 100%;
        margin: 1.75rem auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 15px;
    }
    .modal-sm {
        min-height: calc(100% - 3.5rem);
        max-width: 360px;
        width: 100%;
        margin: 1.75rem auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 15px;
    }
    .modal-content {
        padding: 20px;
        box-shadow: 0 10px 25px 20px rgba(255, 255, 255, 0.06);
        border-radius: 12px;
        width: 100%;
        .modal-header {
            .image-hold {
                --image_same_size: 36px;
                width: var(--image_same_size);
                height: var(--image_same_size);
                @media only screen and (max-width: 479px) {
                    --image_same_size: 30px;
                }
            }
        }
    }
}
.nav-icon-hold {
    max-width: 31px;
}
.bg-dark-image {
    background: #000 url(./../../assets/images/bg-dark.png);
}
.bg-white {
    background: #fff;
}
.bg-light {
    background: #F3F3F3;
}
.h-1000 {
    height: 1000px;
}
.scroll-bar-none {
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar { 
        width: 0;
    }    
}
.fw-500 {
    font-weight: 500;
}
.fw-700 {
    font-weight: 700;
}
.fs-16 {
    font-size: 16px;
}
.pt-60 {
    padding-block-start: 60px;
}
.text-danger {
    color: #FF3E3E;
}
.text-success {
    color: #0EA900;
}
.italic {
    font-style: italic;
}
.hidden {
    overflow: hidden;
}
.timer {
    background-color: #fff;
    border-radius: 6px;
    padding: 16px 25px;
    gap: 0 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    max-width: 620px;
    margin: 0 auto 60px;
    @media only screen and (max-width: 575px) {
        padding: 16px 15px;
        gap: 0 10px;
    }
    li {
        span {
            font-size: 60px;
            font-weight: 400;
            font-family: 'Russo One';
            @media only screen and (max-width: 575px) {
                font-size: 24px;
            }
        }
        small {
            display: block;
            font-size: 16px;
            font-weight: 500;
            text-transform: uppercase;
            @media only screen and (max-width: 575px) {
                font-size: 13px;
            }
        }
    }
}
.onload-padd-top {
    padding-top: 400px !important;
    @media only screen and (max-width: 767px) {
        padding-top: 200px !important;
    }
}