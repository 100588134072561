.luchadores {
    padding-block: 126px 80px;
    @media only screen and (max-width: 767px) {
        padding: 126px 0 40px;
    }
    color: #ADADAD;
    h2, h5 {
        color: #fff;
    }
    h2 {
        justify-content: center;
    }
    .card {
        padding: 45px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 30px 0;
        margin: 0 -15px;
        @media only screen and (max-width: 767px) {
            display: grid;
            grid-template-columns: repeat(auto-fit,minmax(250px,1fr));
            margin: 0;
            gap: 30px 20px;
            padding: 20px 0;
        }
        @media only screen and (max-width: 549px) {
            padding: 20px 15px;
        }
        li {
            width: 25%;
            padding: 0 15px;
            @media only screen and (max-width: 1024px) {
                width: 33.33%;
            }
            @media only screen and (max-width: 767px) {
                width: 100%;
                padding: 0;
            }
        }
        .card-hold {
            background-image: url(./../../../assets/images/luchardores.png);
            background-repeat: no-repeat;
            background-size: cover;
            border: 1px solid #5D0096;
            min-height: 420px;
            padding: 10px 10px 15px;
            border-radius: 16px;
            overflow: hidden;
            cursor: pointer;
            @media only screen and (max-width: 767px) {
                min-height: 100%;
                border-radius: 25px;
            }
            .image-hold {
                border: 1px solid #5D0096;
                margin-block-end: 24px;
                border-radius: 20px;
                overflow: hidden;
                @media only screen and (max-width: 767px) {
                    border-radius: 30px;
                }
                img {
                    width: 100%;
                    object-fit: cover;
                    height: 240px;
                    @media only screen and (max-width: 1199px) {
                        height: 291px;
                    }
                }
            }
            .card-text {
                font-size: 17px;
                h5 {
                    text-transform: capitalize;
                    @media only screen and (max-width: 767px) {
                        font-size: 22px;
                    }
                }
                p {
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    overflow: hidden;
                    -webkit-box-orient: vertical;
                    line-height: 1.3;
                    margin: 0 0 10px;
                }
                span {
                    color: #fff;
                    font-size: 16px;
                }
            }
        }
    }
}