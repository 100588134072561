.scroll-to-top {
    position: fixed;
    bottom: 0;
    right: 55px;
    padding: 20px 8px;
    border-radius: 32px;
    font-family: 'DM Sans';
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    background: #CE1126;
    color: #fff;
    gap: 10px 0;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transform: translateY(75%);
    @media only screen and (max-width: 1023px) {
        bottom: 10%;
        right: 15px;
        padding: 16px 12px 14px;
        transform: translateY(0);
    }
    &:hover {
        color: #fff;
        transform: translateY(0%);
    }
    .scroll-top-text {
        writing-mode: vertical-rl;
        transform: rotate(-180deg);
        margin: 0 -2px 0 0;
        @media only screen and (max-width: 1023px) {
            display: none;
        }
    }
}