@use "./../../assets/scss/abstracts/mixins/mixins.scss" as *;
#header {
    --logo_size: 185px;
    @extend .fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    transition: all .4s ease-in-out;
    @media only screen and (max-width: 767px) {
        --logo_size: 86px;
    }
    // height: 325px;
    &.scroll-top {
        background: var(--body_bg_color) url(./../../assets/images/bg-light.png);
        box-shadow: 0 4px 5px 0 #ccc;
        .header-hold {
            padding: 10px 0;
            align-items: center;
            .pt-60 {
                padding-block-start: 0;
            }
            .logo-height {
                img {
                    height: 100px;
                }
            }
            .logo-wrap {
                text-align: center;
            }
        }
        &+ #main {
            .banner {
                .main-sec {
                    padding: 140px 0 0;
                }
            }
        }
    }
    .header-hold {
        padding: 40px 0 0;
        justify-content: center;
        @media only screen and (max-width: 1023px) {
            justify-content: space-between;
        }
        &> div {
            @media only screen and (min-width: 1023.98px) {
                width: 33.33%;
            }
        }
        .hamburger-main {
            @media only screen and (max-width: 1023px) {
                width: 42px;
            }
        }
        .logo-wrap {
            text-align: center;
            @media only screen and (max-width: 1023px) {
                width: var(--logo_size);
            }
            .logo {
                max-width: var(--logo_size);
            }
        }
        .btn-group {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 0 25px;
            .btn {
                min-width: 120px;
            }
            .btn-light {
                font-weight: 500;
                font-size: 16px;
                font-family: 'DM Sans', sans-serif;
                padding: 19px 20px;
            }
            .btn-icon {
                @media only screen and (max-width: 1023px) {
                    width: 40px;
                }
                @media only screen and (min-width: 1023.98px) {
                    display: none;
                }
            }
        }
        .head-btn {
            @media only screen and (max-width: 1023px) {
                width: 40px;
            }
            .btn {
                @media only screen and (max-width: 767px) {
                    font-size: 14px;
                    font-weight: 500;
                    min-width: 100%;
                }
                @media only screen and (max-width: 576px) {
                    padding: 10px 12px;
                }
            }
            .btn-text {
                @media only screen and (max-width: 1023px) {
                    display: none;
                }
            }
        }
        .sidenav {
            @extend .fixed;
            top: 0;
            left: -100%;
            width: 100%;
            height: 100%;
            transition: all 0.5s;
            &.sidenav-show {
                left: 0;
            }
            .sidenav-hold {
                padding-inline: 150px;
                @media only screen and (max-width: 1199px) {
                    padding-inline: 80px;
                }
                @media only screen and (max-width: 767px) {
                    padding-inline: 30px;
                }
            }
            &:before, &:after {
                --triangle_same_size: 140px;
                --triangle_border_size: 70px;
                content: '';
                @extend .absolute;
                width: var(--triangle_same_size);
                height: var(--triangle_same_size);
                @media only screen and (max-width: 1199px) {
                    --triangle_same_size: 100px;
                    --triangle_border_size: 50px;
                }
                @media only screen and (max-width: 767px) {
                    --triangle_same_size: 50px;
                    --triangle_border_size: 40px;
                }
            }
            &:before {
                top: 0;
                left: 0;
                border-bottom: var(--triangle_border_size) solid transparent;
                border-left: var(--triangle_border_size) solid var(--nav_top_triangle_bg);
                border-right: var(--triangle_border_size) solid transparent;
                border-top: var(--triangle_border_size) solid var(--nav_top_triangle_bg);
            }
            &:after {
                bottom: 0;
                right: 0;
                border-bottom: var(--triangle_border_size) solid var(--nav_bottom_triangle_bg);
                border-right: var(--triangle_border_size) solid var(--nav_bottom_triangle_bg);
                border-left: var(--triangle_border_size) solid transparent;
                border-top: var(--triangle_border_size) solid transparent;
            }
            #nav {
                height: 100vh;
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: auto 1fr auto;
                grid-template-areas: 'nav_header' 'nav_body' 'nav_footer';
                @media only screen and (max-width: 767px) {
                    height: calc(100vh - 100px);
                }
                .nav-header {
                    --logo_size: 144px;
                    grid-area: nav_header;
                    margin: 0 0 40px;
                    display: flex;
                    @extend .header-hold;
                    justify-content: unset;
                    @media only screen and (max-width: 1199px) {
                        margin: 0;
                    }
                    @media only screen and (max-width: 767px) {
                        --logo_size: 86px;
                        display: grid;
                        grid-template-columns: 36px 1fr;
                        margin: 0 0 10px;
                        &> div {
                            width: 100%;
                        }
                    }
                    .logo-wrap {
                        text-align: center;
                        @media only screen and (max-width: 1023px) {
                            margin: 0 auto;
                            .logo {
                                margin-inline-start: -35px;
                            }
                        }
                    }
                    .btn {
                        &.btn-success {
                            &:hover {
                                --btn_success_color: #fff;
                            }
                        }
                        &.btn-light {
                            background: #252525;
                            color: #fff;
                            &:hover {
                                background: #fff;
                                color: #000;
                            }
                        }
                    }
                    .mint-btn {
                        @media only screen and (max-width: 1023px) {
                            display: none;
                        }
                    }
                }
                .nav-body {
                    grid-area: nav_body;
                    text-align: center;
                    padding: 23px 0;
                    overflow-y: auto;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    @media only screen and (max-width: 1199px) {
                        padding: 10px 0;
                    }
                    li {
                        @media only screen and (min-height: 600px) {
                            margin: 0 0 20px;
                        }
                        a {
                            --anchor_color: #fff;
                            --anchor_button_font_size: 24px;
                            letter-spacing: 2px;
                            text-transform: capitalize;
                            cursor: pointer;
                            @media only screen and (max-width: 767px) {
                                --anchor_button_font_size: 18px;
                            }
                            &.active {
                                color: var(--anchor_hover_color);
                            }
                        }
                    }
                }
                .nav-footer {
                    grid-area: nav_footer;
                    margin-block: 30px;
                    @media only screen and (max-width: 767px) {
                        justify-content: center;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        margin-block-end: 10px;
                    }
                    li {
                        &:not(:first-child, :last-child) {
                            padding-inline: 30px;
                        }
                    }
                }
            }
        }
    }
}
.hidden {
    #header {
        &.scroll-top {
            .sidenav {
                #nav {
                    .nav-header {
                        .pt-60 {
                            padding-block-start: 60px;
                        }
                    }
                }
            }
        }
    }
}