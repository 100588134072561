.incorrect-chain {
    @media only screen and (max-width: 1023px) {
        display: none;
    }
    &.modal {
        .modal-content {
            background: #fff url('./../../assets/images/bg-light.png');
            .modal-body {
                padding: 20px 0;
                strong {
                    font-size: 18px;
                    color: #FF3E3E;
                }
            }
        }
    }
}