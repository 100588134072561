.artist {
    padding: 126px 0 80px;
    @media only screen and (max-width: 767px) {
        padding: 124px 0 80px;
    }
    .container {
        max-width: 1030px;
    }
    .artist-hold {
        padding: 20px 0 40px;
        gap: 0 60px;
        align-items: center;
        grid-template-columns: 440px 1fr;
        @media only screen and (max-width: 991px) {
            padding: 40px 0 60px 15px;
            gap: 0 30px;
            grid-template-columns: 1fr 1fr;
        }
        @media only screen and (max-width: 767px) {
            padding: 40px 0 20px 15px;
        }
        @media only screen and (max-width: 639px) {
            grid-template-columns: 1fr;
            padding: 20px 0;
            gap: 40px 0;
        }
        .artist-card {
            @media only screen and (max-width: 639px) {
                order: 2;
                padding: 0 40px;
            }
            .image-wrap {
                width: 440px;
                height: 500px;
                border-radius: 16px;
                background: linear-gradient(74.08deg, #006341 13.93%, #CE1126 98.57%);
                transform: rotate(-5deg);
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    height: unset;
                }
                img {
                    width: 480px;
                    height: 500px;
                    transform: rotate(6deg);
                    border-radius: 16px;
                    overflow: hidden;
                    object-fit: fill;
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                        height: unset;
                    }
                }
            }
        }
        .artist-text {
            .text-hold {
                padding: 30px 28px;
                border-radius: 20px;
                background: linear-gradient(to bottom, #f3f3f3, #e6e5e5);
                line-height: 1.6;
                font-size: 16px;
                @media only screen and (max-width: 767px) {
                    padding: 20px;
                }
                h5 {
                    @media only screen and (max-width: 479px) {
                        font-size: 20px;
                    }
                }
                p {
                    margin: 0 0 20px;
                }
                .social-icon {
                    display: flex;
                    align-items: center;
                    gap: 0 33px;
                    @media only screen and (max-width: 767px) {
                        gap: 0 25px;
                    }
                    .icon-hold {
                        max-width: 24px;
                        max-height: 24px;
                    }
                }
            }
        }
    }
}