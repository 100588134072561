@use "./../variables/variables.scss" as *;
@mixin font_style_display($font-weight, $font-style, $font-display) {
  font-weight: $font-weight;
  font-style: $font-style;
  font-display: $font-display;
}
@mixin transition($for, $time-in, $type, $time-out) {
  transition: $for $time-in $type $time-out;
}
@each $key, $val in $dynamic_classes{
  .#{$key} {
    display: #{$val};
  }
}
@each $key, $val in $dynamic_positions{
  .#{$key} {
    position: #{$val};
  }
}
@each $key, $val in $dynamic_text_classes{
  .text-#{$key} {
    text-align: #{$val};
  }
}