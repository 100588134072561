@use "./../../../assets/scss/abstracts/mixins/mixins.scss" as *;
.join-mwf {
    padding-top: 126px;
    .join-project {
        @extend .grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        @media only screen and (max-width: 991px) {
            align-items: flex-end;
        }
        @media only screen and (max-width: 767px) {
            align-items: center;
        }
        @media only screen and (max-width: 767px) {
            grid-template-columns: 1fr;
            gap: 35px 0;
        }
        .join-project-img {
            margin: auto 0 0;
            @media only screen and (max-width: 767px) {
                order: 2;
            }
        }
        .join-project-text {
            margin-block-end: 90px;
            @media only screen and (max-width: 991px) {
                margin: 0 0 40px;
            }
            @media only screen and (max-width: 767px) {
                margin: 0;
            }
            h2 {
                @media only screen and (max-width: 767px) {
                    margin: 0 0 20px;
                }
            }
            .social-icon {
                @extend .flex;
                align-items: center;
                gap: 0 30px;
                @media only screen and (max-width: 479px) {
                    gap: 0 15px;
                }
                @media only screen and (max-width: 767px) {
                    justify-content: center;
                }
                .btn {
                    padding: 16px 45px;
                    @media only screen and (max-width: 479px) {
                        padding: 16px 25px;
                        font-size: 18px;
                    }
                }
                .icon-wrap {
                    --bg_light_blue_size: 60px;
                    width: var(--bg_light_blue_size);
                    height: var(--bg_light_blue_size);
                    border-radius: 6px;
                    background: #00ACEE;
                    @extend .flex;
                    align-items: center;
                    justify-content: center;
                    @media only screen and (max-width: 479px) {
                        --bg_light_blue_size: 50px;
                    }
                }
            }
            .mwf-list {
                max-height: 290px;
                overflow: hidden;
                @media only screen and (max-width: 1199px) {
                    max-height: 240px;
                }
                @media only screen and (max-width: 1023px) {
                    max-height: 200px;
                }
                @media only screen and (max-width: 1023px) {
                    max-height: 100px;
                }
                @media only screen and (max-width: 1023px) {
                    max-height: 155px;
                }
                li {
                    display: grid;
                    grid-template-columns: 18px 1fr;
                    gap: 0 12px;
                    line-height: 1.6;
                    margin-block-end: 9px;
                    &:nth-child(3) {
                        margin-block-end: 25px;
                    }
                    &:last-child {
                        margin-block-end: 0;
                    }
                    .image-hold {
                        margin-block-start: 5px;
                    }
                }
            }
            .collapse-toggle {
                position: absolute;
                bottom: 0;
                left: 0;
                font-size: 16px;
                width: 100%;
                padding: 70px 10px 16px;
                background: linear-gradient(to bottom, rgba(230, 230, 230, 0) 0%, #E6E6E6 73.96%);
                border-radius: 0px 0px 16px 16px;
                @media only screen and (max-width: 1023px) {
                    background: linear-gradient(180deg, rgba(230, 230, 230, 0) 0%, #E6E6E6 66.15%);
                }
                &:hover {
                    color: var(--anchor_color);
                }
            }
            &.collapse-open {
                margin: 0 0 27px;
                .text-hold {
                    padding: 25px 25px 0;
                }
                .mwf-list {
                    max-height: 100%;
                    overflow: unset;
                    li {
                        display: grid;
                        grid-template-columns: 18px 1fr;
                        gap: 0 12px;
                        &:nth-child(3) {
                            margin-block-end: 9px;
                        }
                        .image-hold {
                            margin-block-start: 5px;
                        }
                    }
                }
                .collapse-toggle {
                    position: static;
                    background-color: transparent;
                    border-radius: 0;
                    padding: 16px 10px;
                }
            }
        }
        .text-hold {
            position: relative;
            padding: 25px 25px 16px;
            border-radius: 20px;
            background: linear-gradient(to bottom, #f3f3f3, #e6e5e5);
            line-height: 30px;
            font-size: 16px;
            margin-block-end: 25px;
            @media only screen and (max-width: 1023px) {
                padding: 16px 20px;
            }
            @media only screen and (max-width: 767px) {
                font-size: 15px;
            }
            p {
                margin-block-end: 15px;
            }
        }
    }
}