.bg-dark-pattren {
    background-color: #000;
    background-image: url(./../../assets/images/bg-dark-pattren.png);
    background-repeat: repeat;
    position: relative;
    isolation: isolate;
    &:before, &:after {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        background-repeat: no-repeat;
        mix-blend-mode: plus-lighter;
        z-index: -1;
        background-size: 100% 100%;
    }
    &:before {
        background-image: url(./../../assets/images/bg-gradient-top.png);
        top: 0;
        height: 330px;
    }
    &:after {
        background-image: url(./../../assets/images/bg-gradient-bottom.png);
        bottom: 0;
        height: 755px;
    }
}