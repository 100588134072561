.partners {
    // padding: 30px 0 90px;
    padding: 126px 0 200px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.03) 0%, rgba(0, 0, 0, 0.1) 100%), #FFFFFF url(./../../../assets/images/bg-light.png);
    background-attachment: fixed;
    @media only screen and (max-width: 639px) {
        padding: 130px 0 60px;
    }
    h2 {
        justify-content: center;
    }
    .partner-text-head {
        p {
            line-height: 1.6;
        }
    }
    .partner-cards {
        display: flex;
        gap: 0 30px;
        align-items: center;
        justify-content: center;
        padding-block-start: 45px;
        @media only screen and (max-width: 767px) {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
            gap: 20px;
        }
        li {
            width: 300px;
            height: 110px;
            background: #fff;
            border-radius: 8px;
            box-shadow: 0 2px 12px 4px rgba(0, 0, 0, 0.06);
            display: grid;
            align-items: center;
            @media only screen and (max-width: 767px) {
                width: 100%;
                height: unset;
            }
            &.masked-republic {
                background-color: #C8222A;
            }
            &.virtua {
                background-color: #6610F2;
            }
            a {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 23px 20px;
            }
            .img-hold {
                width: 170px;
            }
        }
    }
}