.roadmap {
    padding-block-start: 126px;
    background-color: #F3F3F3;
    background-image: url('./../../../assets/images/road-map-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    @media only screen and (max-width: 767px) {
        background-image: url('./../../../assets/images/mobile-roadmap-bg.png');
        padding-block-start: 130px;
        text-align: center;
    }
    .slick-list {
        overflow: unset;
        .slick-track {
            @media only screen and (max-width: 767px) {
                display: flex !important;
                align-items: self-end;
            }
        }
    }
   .roadmap-text-head {
        max-width: 752px;
        padding-inline: 15px;
        margin-inline: auto;
        position: relative;
        z-index: 1;
        @media only screen and (max-width: 767px) {
            font-size: 16px;
            margin: 0 0 30px;
        }
        h2 {
            justify-content: center;
        }
   }
    .roadmap-slider {
        position: relative;
        .roadmap-slider-hold {
            display: grid;
            grid-template-columns: 530px 1fr;
            align-items: center;
            @media only screen and (max-width: 767px) {
                grid-template-columns: 1fr;
            }
            .text-hold {
                margin: -8rem 0 0;
                @media only screen and (max-width: 767px) {
                    margin: 0;
                }
                .date-time {
                    font-size: 20px;
                    display: inline-block;
                    @media only screen and (max-width: 767px) {
                        margin: 0 0 6px;
                    }
                }
                h3 {
                    margin: 0 0 10px;
                    color: #CE1126;
                    text-transform: capitalize;
                }
                p {
                    line-height: 1.6;
                    margin-block-end: 30px;
                    max-width: 440px;
                    @media only screen and (max-width: 767px) {
                        margin: 0 auto 30px;
                    }
                }
                .slider-control {
                    display: flex;
                    align-items: center;
                    gap: 0 21px;
                    @media only screen and (max-width: 767px) {
                        justify-content: center;
                    }
                    .left-arrow {
                        svg {
                            transform: rotate(-180deg);
                        }
                    }
                    .icon-hold {
                        cursor: pointer;
                    }
                    .slider-digits {
                        font-family: 'Do Hyeon';
                        font-weight: 400;
                        font-size: 22px;
                        color: #353535;
                    }
                }
            }
            .image-hold {
                opacity: 0.5;
                margin-top: auto;
                height: 765px;
                @media only screen and (max-width: 767px) {
                    width: 520px;
                    height: auto;
                    display: flex;
                    align-items: flex-end;
                    overflow: hidden;
                    margin: 0 auto;
                }
                @media only screen and (max-width: 639px) {
                    width: 375px;
                    // height: 490px;
                }
                @media only screen and (max-width: 575px) {
                    width: 300px;
                    // height: 420px;
                }
                img {
                    margin-inline-start: auto;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    @media only screen and (max-width: 767px) {
                        height: auto;
                        //margin: 0 0 -50px;
                    }
                }
            }
        }
        .slider-image {
            margin-inline-start: auto;
            position: absolute;
            top: calc(100% - 35%);
            right: 0;
            width: 100%;
            @media only screen and (max-width: 767px) {
                top: unset;
                bottom: 0;
            }
            @media only screen and (max-width: 479px) {
                top: unset;
                bottom: 40px;
            }
            &.roadmap-slide-1 {
                --container_width: 1170px;
                --container_offset: calc(((100vw - var(--container_width)) / 2) + 20px);
                padding-left: var(--container_offset);
            }
            &.roadmap-slide-1, 
            &.roadmap-slide-2 {
                img {
                    @media only screen and (max-width: 767px) {
                        margin-inline-start: 30px;
                    }
                    @media only screen and (max-width: 479px) {
                        margin-inline-start: 20px;
                        width: 95%;
                    }
                }
            }
            img {
                margin-inline-start: auto;
                width: 100%;
            }
        }
    }
    .slick-slide {
        &.slick-active {
            &.slick-current {
                z-index: 4;
            }
        }
    }
    
    .slick-slider {
        --slider_arrow_disabled: 0.4;
        [data-index="0"] {
            &.slick-current {
                .roadmap-slider {
                    .left-arrow {
                        svg {
                            path {
                                opacity: var(--slider_arrow_disabled);
                                pointer-events: none;
                            }
                        }
                    }
                }
            }
        }
        [data-index="3"] {
            &.slick-current {
                .roadmap-slider {
                    .right-arrow {
                        svg {
                            path {
                                opacity: var(--slider_arrow_disabled);
                                pointer-events: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

