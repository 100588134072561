.mobile-mint-modal {
    @media only screen and (min-width: 1023.98px) {
        display: none;
    }
    &.modal {
        .modal-content {
            background: #fff url('./../../assets/images/bg-light.png');
            .modal-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                h5 {
                    --body_font_family: 'Russo One';
                    @media only screen and (max-width: 479px) {
                        font-size: 20px;
                        margin: 0;
                    }
                }
            }
            .modal-body {
                padding: 35px 0 25px;
                img {
                    width: 90px;
                    margin-inline: auto;
                    margin-block-end: 25px;
                }
                h5 {
                    --body_font_family: 'Russo One';
                    margin-block-end: 8px;
                    font-weight: 400;
                    font-size: 22px;
                    @media only screen and (max-width: 479px) {
                        font-size: 20px;
                    }
                }
                p {
                    color: #606060;
                    font-size: 20px;
                    @media only screen and (max-width: 479px) {
                        font-size: 17px;
                    }
                    &:not(:last-child) {
                        margin: 0 0 15px;
                    }
                }
            }
        }
    }
}