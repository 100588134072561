.faq {
    padding: 126px 0 100px;
    @media only screen and (max-width: 767px) {
        padding: 130px 0 80px;
    }
    .container {
        max-width: 900px;
    }
    h2 {
        justify-content: center;
        margin: 0 0 25px;
        @media only screen and (max-width: 767px) {
            margin: 0 0 10px;
        }
    }
    .faq-hold {
        padding: 25px 0;
        display: grid;
        align-items: flex-start;
        // grid-template-columns: 1fr 320px;
        gap: 24px 30px;
        @media only screen and (max-width: 767px) {
            // grid-template-columns: 1fr;
            padding: 10px 0 25px;
        }
        .accordion {
            border: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            // max-height: 450px;
            overflow-y: auto;
            -ms-overflow-style: none;
            scrollbar-width: none;
            @media only screen and (max-width: 767px) {
                max-height: 100%;
            }
            &::-webkit-scrollbar { 
                width: 0;
            } 
            .accordion__item {
                &:not(:last-child) {
                    margin-block-end: 10px;
                }
                &+ .accordion__item {
                    border: 0;
                }
                .accordion__heading {
                    .accordion__button {
                        background-color: #F8F8F8;
                        color: #353535;
                        border-radius: 6px;
                        padding: 15px 20px;
                        font-size: 18px;
                        font-weight: 500;
                        position: relative;
                        @media only screen and (max-width: 767px) {
                            padding: 15px 30px 15px 15px;
                        }
                        &:before {
                            position: absolute;
                            top: 50%;
                            right: 20px;
                            transform: translateY(-50%);
                            height: 1px;
                            width: 14px;
                            background: #000;
                            margin: 0;
                            border: 0;
                            @media only screen and (max-width: 767px) {
                                right: 13px;
                            }
                            @media only screen and (max-width: 575px) {
                                top: 26px;
                            }
                        }
                        &:after {
                            position: absolute;
                            content: '';
                            top: 50%;
                            right: 26px;
                            transform: translateY(-50%);
                            height: 14px;
                            width: 1px;
                            background: #000;
                            margin: 0;
                            border: 0;
                            @media only screen and (max-width: 767px) {
                                right: 19px;
                            }
                            @media only screen and (max-width: 575px) {
                                top: 26px;
                            }
                        }
                        &[aria-expanded='true'], 
                        &[aria-selected='true'] {
                            &:before, &:after {
                                background: #CE1126;
                            }
                            &:before {
                                transform: translateY(-50%) rotate(-45deg);
                            }
                            &:after {
                                transform: translateY(-50%) rotate(-40deg);
                            }
                        }
                    }
                }
                .accordion__panel {
                    padding: 0 55px 20px 20px;
                    background: #F8F8F8;
                    color: #606060;
                    font-size: 16px;
                    border-radius: 0 0 6px 6px;
                    @media only screen and (max-width: 767px) {
                        padding: 0 20px 20px;
                    }
                    p {
                        span {
                            line-height: 1.7;
                            &:first-child {
                                margin-block-start: 20px;
                            }
                        }
                    }
                }
            }
        }
        .contact-us {
            padding: 20px;
            border-radius: 8px;
            position: relative;
            display: flex;
            // flex-direction: column;
            align-items: center;
            justify-content: space-between;
            @media only screen and (max-width: 767px) {
                flex-direction: column;
                justify-content: center;
                padding: 40px 34px;
            }
            &:before {
                content: "";
                position: absolute;
                inset: 0;
                border-radius: var(--btn_radius);
                padding: 2px;
                background: linear-gradient(to bottom, #006341, #CE1126);
                -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
                -webkit-mask-composite: xor;
            }
            .text-hold {
                text-align: left;
                @media only screen and (max-width: 767px) {
                    text-align: center;
                }
                h5 {
                    color: #CE1126;
                    margin-block-end: 13px;
                    font-family: 'Russo One';
                    font-weight: 400;
                    text-transform: none;
                    @media only screen and (max-width: 767px) {
                        line-height: 1.2;
                        margin-block-end: 13px;
                    }
                }
                p {
                    font-size: 16px;
                    @media only screen and (max-width: 767px) {
                        margin-block-end: 32px;
                        line-height: 1.8;
                    }
                }
            }
            .btn {
                @media only screen and (max-width: 767px) {
                    min-width: 230px;
                }
            }
        }
    }
}