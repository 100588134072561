.cards-modal {
    .modal-content {
        background-color: #000;
        background-image: url(./../../assets/images/bg-dark.png);
        background-repeat: no-repeat;
        .modal-header {
            display: flex;
            justify-content: space-between;
            .cross-circle {
                --cross_same_size: 36px;
                width: var(--cross_same_size);
                height: var(--cross_same_size);
                background-color: #fff;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .modal-body {
            display: grid;
            grid-template-columns: 330px 1fr;
            gap: 0 30px;
            padding: 40px 30px 24px;
            @media only screen and (max-width: 767px) {
                grid-template-columns: 250px 1fr;
                align-items: center;
            }
            @media only screen and (max-width: 679px) {
                grid-template-columns: 1fr;
                padding: 25px 0;
            }
            .card-modal-img {
                @media only screen and (max-width: 679px) {
                    max-width: 320px;
                    margin-inline: auto;
                }
                @media only screen and (max-width: 479px) {
                    max-width: 100%;
                    margin-inline: unset;
                }
                img {
                    width: 100%;
                }
            }
            .text-hold {
                padding-block-start: 20px;
                color: #ADADAD;
                font-size: 16px;
                text-align: left;
                @media only screen and (max-width: 679px) {
                    text-align: center;
                }
                strong {
                    font-size: 30px;
                    margin-block-end: 16px;
                    color: #fff;
                }
                p {
                    line-height: 1.7;
                    margin-block-end: 40px;
                    @media only screen and (max-width: 679px) {
                        text-align: center;
                    }
                }
                .social-icon {
                    align-items: center;
                    gap: 0 30px;
                    @media only screen and (max-width: 679px) {
                        justify-content: center;
                    }
                }
            }
        }
    }   
}