.mint-modal {
    @media only screen and (max-width: 1023px) {
        display: none;
    }
    &.modal {
        &.modal-overlay {
            .modal-lg {
                padding: 0 15px;
                .modal-content {
                    background: #fff url('./../../assets/images/bg-light.png');
                    .modal-header {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        h5 {
                            --body_font_family: 'Russo One';
                            @media only screen and (max-width: 1023px) {
                                font-weight: 400;
                                margin: 0;
                            }
                        }
                    }
                    .modal-body {
                        padding: 20px;
                        .mint-detail {
                            padding: 16px 30px;
                            background: #fff;
                            border: 1px solid #bbb;
                            border-radius: 8px;
                            margin-block-end: 20px;
                            color: #353535;
                            li {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                span {
                                    font-size: 24px;
                                    small {
                                        font-size: 18px;
                                        margin-inline-end: 10px;
                                    }
                                }
                                &:not(:first-child) {
                                    border-top: 1px solid #E7E7E7;
                                    padding-block-start: 16px;
                                }
                                &:first-child {
                                    margin-block-end: 16px;
                                }
                            }
                        }
                        p, .remain-wallet, .btn-dark {
                            margin-block-end: 16px;
                        }
                        .timer {
                            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                            margin-block-start: 40px;
                        }
                        .remain-wallet {
                            font-size: 34px;
                            color: #353535;
                        }
                        .error-message {
                            margin-block-end: 25px;
                        }
                        .success-message {
                            padding-block-start: 20px;
                            margin-block-end: 40px;
                            h3 {
                                font-size: 40px;
                                font-family: 'Russo One';
                                color: #0EA900;
                                line-height: 1.2;
                                text-transform: unset;
                                margin-block-end: 20px;
                            }
                            .trans-detail {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color: #1E78FF;
                                font-size: 18px;
                                font-family: var(--body_font_family);
                                .image-hold {
                                    width: 16px;
                                    margin-inline-start: 10px;
                                }
                            }
                        }
                        .mint-loader {
                            position: absolute;
                            top: 0;
                            max-width: 230px;
                            height: 100%;
                            left: 0;
                            display: none;
                            width: 100%;
                            align-items: center;
                            justify-content: center;
                            background: #000;
                            &.active {
                                display: flex;
                            }
                            img {
                                height: 100%;
                            }
                        }
                        .btn {
                            min-width: 230px;
                        }
                    }
                    .modal-footer {
                        margin-block-end: 10px;
                        ul {
                            background: #fff;
                            box-shadow: 0px 0px 32px 10px rgba(0, 0, 0, 0.1);
                            border-radius: 8px;
                            padding: 15px 24px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            font-size: 16px;
                            color: #000;
                            li {
                                display: flex;
                                align-items: center;
                                gap: 0 16px;
                                .image-hold {
                                    max-width: 82px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.mint-quantity {
    margin-block-end: 10px;
    &.disabled {
        .mint-quantity-text {
            h5 {
                color: #ababab;
            }
            span {
                color: #CD878F;
            }
        }
        .mint-quantity-hold {
            border-color: #f3f3f3;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            .less-quantity, .add-quantity {
                background: #ececec;
                color: #adadad;
                pointer-events: none;
            }
            .numb-quantity{
                color: #ababab;
            }
        }
    }
    .mint-quantity-text {
        margin: 0 0 6px;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        h5 {
            color: #353535;
            margin: 0;
        }
        span {
            font-size: 14px;
            color: #CE1126;
        }
    }
    .mint-quantity-hold {
        background: #fff;
        border: 1px solid #bbb;
        padding: 10px 15px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        .less-quantity, .add-quantity {
            --mint_quantity_same_size: 40px;
            width: var(--mint_quantity_same_size);
            height: var(--mint_quantity_same_size);
            font-size: 30px;
            background: #CEF6E9;
            color: #000;
            border-radius: 8px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .numb-quantity {
            width: 120px;
            font-size: 24px;
            color: #353535;
        }
    }
}

#header .header-hold .btn-group .mint-modal .btn {
    min-width: 230px;
    text-transform: capitalize;
}

.progress-wrapper {
    width: 100%;
    margin: 0;
    color: #fff;
    padding-bottom: 5px;
    margin-block-end: 25px;
    max-width: 600px;
    margin-inline: auto;
    .progress-text {
        color: #353535;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        small {
            font-size: 16px;
            margin: 0 0 5px;
        }
        span {
            font-size: 34px;
            font-family: 'Russo One';
        }
    }
    section {
        width: 100%;
        background: #fff;
        box-shadow: 0px 4px 32px 10px rgba(0, 0, 0, 0.1);
        height: 26px;
        border-radius: 15px;
        padding: 0;
        position: relative;
        overflow: hidden;
        .progress-thumb {
            position: absolute;
            left: 0;
            border-radius: 50px;
            top: 0;
            height: 100%;
            background: linear-gradient(112.9deg, #006341 17.19%, #007E53 49.48%, #006341 86.46%);
            overflow: hidden;
        }
        .percentage-text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 14px;
            color: #353535;
        }
    }
}