.how-it-works {
    padding: 126px 0 0;
    @media only screen and (max-width: 767px) {
        padding: 130px 0 0;
    }
    h2, h5 {
        color: #fff;
    }
    h2 {
        justify-content: center;
        @media only screen and (max-width: 479px) {
            margin: 0 auto;
            text-align: center;
            align-items: start;
            line-height: 36px;
            span {
                margin: 6px 0 0;
            }
        }
    }
    .container {
        max-width: 1030px;
        margin-inline: auto;
    }
    .how-works-hold {
        .text-hold {
            font-size: 20px;
            line-height: 1.6;
            color: #ADADAD;
            @media only screen and (max-width: 767px) {
                font-size: 16px;
            }
            h2 {
                margin-block-end: 32px;
            }
            p {
                &:not(:last-child) {
                    margin-block-end: 20px;
                }
            }
            &+ p {
                padding-block-start: 44px;
                color: #fff;
                font-size: 24px;
                @media only screen and (max-width: 767px) {
                    text-align: center;
                    font-size: 20px;
                }
            }
        }
    }
}